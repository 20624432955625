import { TweenLite } from 'gsap/all'

/**
 * Accordion module
 */
class Accordion {
  constructor() {
    // Define plugin defaults
    const DEFAULTS = {
      tabContainer: 'js-tab-container',
      tabButton: 'js-tab-action',
      tabContent: 'js-tab-content',
      activeTab: 'is-active',
    }

    /**
     * Plugin elements
     * @type {Array}
     */
    this.elements = Array.from(document.querySelectorAll(`[data-accordion]`))

    /**
     * Plugin settings
     * @type {Object}
     */
    this.settings = Object.assign({}, DEFAULTS)

    if (this.elements.length) {
      this.init()
    }
  }

  init() {
    this.elements.forEach((item) => {
      // init functions for accordion component
      this._events(item)
    })
  }

  _events(el) {
    el.addEventListener(
      'click',
      (evt) => {
        if (
          evt.target !== evt.currentTarget &&
          evt.target.classList.contains(this.settings.tabButton)
        ) {
          let clickedEl = evt.target
          this.tabAction(clickedEl)
        }
        evt.stopPropagation()
      },
      false
    )
  }

  /**
   * set a tab and content active
   * @params $element
   */
  tabAction(el) {
    let elSingleAccordionBlock = el.closest(`.${this.settings.tabContainer}`)
    let panel = elSingleAccordionBlock.querySelector(
      `.${this.settings.tabContent}`
    )

    if (!elSingleAccordionBlock.classList.contains(this.settings.activeTab)) {
      Array.from(
        elSingleAccordionBlock.parentElement.querySelectorAll(
          `.${this.settings.tabContainer}`
        )
      ).forEach((elem) => {
        if (elem.classList.contains(this.settings.activeTab)) {
          elem.classList.remove(this.settings.activeTab)
          elem
            .querySelector(`.${this.settings.tabContent}`)
            .setAttribute('aria-hidden', 'true')
          elem
            .querySelector(`.${this.settings.tabButton}`)
            .setAttribute('aria-expanded', 'false')

          TweenLite.to(
            elem.querySelector(`.${this.settings.tabContent}`),
            0.2,
            {
              height: 0,
            }
          )
        }
      })
      panel.setAttribute('aria-hidden', 'false')
      el.setAttribute('aria-expanded', 'true')
      TweenLite.set(panel, {
        height: 'auto',
      })
      TweenLite.from(panel, 0.2, {
        height: 0,
      })
      elSingleAccordionBlock.classList.add(this.settings.activeTab)
    } else {
      panel.setAttribute('aria-hidden', 'true')
      el.setAttribute('aria-expanded', 'false')
      TweenLite.to(panel, 0.2, {
        height: 0,
      })
      elSingleAccordionBlock.classList.remove(this.settings.activeTab)
    }
  }
}

export default new Accordion()
