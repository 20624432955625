export default class Modal {
  constructor(id) {
    this.instance = {
      backdrop: null,
      element: null,
      body: null,
      name: null,
      id: id,
    }

    this.events = {
      open: {},
      close: {},
    }

    this.init()
  }

  init() {
    this.createModal()
    this.initTriggers()
    this.initClose()
  }

  createModal() {
    this.instance.element = document.getElementById(this.instance.id)

    if (!this.instance.element) {
      return
    }

    this.instance.body =
      this.instance.element.querySelector('[data-modal-body]')
  }

  initTriggers() {
    const triggers = document.querySelectorAll('[data-modal-trigger]')

    if (!triggers.length) {
      return
    }

    Array.from(triggers).forEach((trigger) => {
      const { triggerEvent } = trigger.dataset
      trigger.addEventListener(triggerEvent || 'click', (e) => {
        e.preventDefault()

        const { modalTrigger } = trigger.dataset

        if (!modalTrigger) {
          return
        }

        const template = document.querySelector(
          `[data-modal-template="${modalTrigger}"]`
        )

        if (!template) {
          return
        }

        this.instance.body.appendChild(template.content.cloneNode(true))
        this.initClose(true)

        this.instance.name = modalTrigger

        document.body.classList.add('is-modal-opened')

        if (
          !(
            this.events.open[modalTrigger] &&
            this.events.open[modalTrigger].length
          )
        ) {
          return
        }

        this.events.open[modalTrigger].forEach((callback) => {
          callback.call(null, {
            trigger,
            event: e,
            body: this.instance.body,
          })
        })
      })
    })
  }

  initClose(local = false) {
    const triggers = (local ? this.instance.body : document).querySelectorAll(
      '[data-modal-close]'
    )

    if (!triggers.length) {
      return
    }

    Array.from(triggers).forEach((trigger) => {
      trigger.addEventListener('click', () => {
        this.closeModal()
      })
    })

    document.onkeydown = (e) => {
      if (e.key === 'Escape') {
        this.closeModal()
      }
    }
  }

  closeModal() {
    document.body.classList.remove('is-modal-opened')

    setTimeout(() => {
      this.removeChildren()
    }, 200)

    if (
      !(
        this.instance.name &&
        this.events.close[this.instance.name] &&
        this.events.close[this.instance.name].length
      )
    ) {
      this.instance.name = null
      return
    }

    this.events.close[this.instance.name].forEach((callback) => {
      callback.call(null)
    })
  }

  removeChildren() {
    while (this.instance.body.firstChild) {
      this.instance.body.removeChild(this.instance.body.firstChild)
    }
  }

  on(event, template, callback) {
    this.events[event][template] = this.events[event][template] || []
    this.events[event][template].push(callback)
  }
}
