import '@/lib/Navigation'
import '@/lib/StageIntersectionObserver'
import '@/lib/Intro'
import '@/lib/Accordion'
import '@/lib/Cookies'
import '@/lib/formSteps'
import '@/lib/ImageModal'
import '@/lib/Slider'

import vhCheck from 'vh-check'

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR')
  })
}

vhCheck()

if (document.querySelector('[data-vue-root]')) {
  import(/* webpackChunkName: "vc" */ './vue-components').then(
    (vueComponents) => {
      vueComponents.default.mount()
    }
  )
}
