class FormSteps {
  constructor() {
    this.options = {
      currentStep: 1,
      stepWrapperClass: '.step',
      radioTaFields: {},
      formData: {},
      formError: null,
      formFieldTypes: {
        checkbox_group: [],
        radio_group: null,
        textarea: '',
        text: '',
        email: '',
        checkbox: false,
        hidden: (field) => {
          return field.value || null
        },
      },
      regex: {
        email:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },
    }
  }

  init() {
    this.buildFormData()
    this.collectRadioTaGroup()
    return {
      options: { ...this.options },
      nextStep: this.nextStep,
      prevStep: this.prevStep,
      isStepValid: this.isStepValid,
      formChange: this.formChange,
      isRadioTaDisabled: this.isRadioTaDisabled,
      formSubmit: this.formSubmit,
      formAnswers: this.formAnswers,
    }
  }

  buildFormData() {
    const formFieldKeys = document.querySelectorAll('[data-form-field-id]')

    Array.from(new Set(formFieldKeys)).forEach((formFieldKey) => {
      this.options.formData[`${formFieldKey.dataset.formFieldId}`] =
        typeof this.options.formFieldTypes[
          `${formFieldKey.dataset.formFieldType}`
        ] !== 'function'
          ? this.options.formFieldTypes[`${formFieldKey.dataset.formFieldType}`]
          : this.options.formFieldTypes[
              `${formFieldKey.dataset.formFieldType}`
            ](formFieldKey)
    })
  }

  collectRadioTaGroup() {
    const groups = document.querySelectorAll('[data-id]')

    if (!groups) {
      return
    }

    Array.from(groups).forEach((group) => {
      this.options.radioTaFields[`${group.dataset.id}`] = {}
      this.options.radioTaFields[`${group.dataset.id}`]['selected'] = null
    })
  }

  isRadioTaDisabled(id) {
    return (
      !this.options.radioTaFields[`radioTa${id}`].selected ||
      this.options.radioTaFields[`radioTa${id}`].selected ===
        this.$refs[`textarea${id}`].dataset?.disabledRadio
    )
  }

  formChange() {
    const isDisabled = !this.isStepValid()
    if (isDisabled) {
      this.$refs.next.disabled = true
      this.$refs.next.classList.add(
        'disabled',
        'opacity-25',
        'pointer-events-none'
      )
    } else {
      this.$refs.next.disabled = false
      this.$refs.next.classList.remove(
        'disabled',
        'opacity-25',
        'pointer-events-none'
      )
    }
  }

  nextStep() {
    this.options.currentStep++
  }

  prevStep() {
    this.options.currentStep--
  }

  isStepValid() {
    const stepEl = this.$el.querySelectorAll(this.options.stepWrapperClass)[
      this.options.currentStep - 1
    ]
    const fieldGroups = stepEl.getElementsByTagName('fieldset')
    const stepValidated = []
    const requiredFields = Array.from(fieldGroups).filter((required) =>
      Object.hasOwn(required.dataset, 'required')
    )

    Array.from(fieldGroups).forEach((fieldset) => {
      if (Object.hasOwn(fieldset.dataset, 'required')) {
        switch (fieldset.dataset.formFieldType) {
          case 'checkbox_group':
          case 'radio_group':
            // eslint-disable-next-line no-case-declarations
            const inputs = fieldset.getElementsByTagName('input')
            // eslint-disable-next-line no-case-declarations
            const valid = Array.from(inputs).some((input) => input.checked)
            stepValidated.push(valid)
            break
          case 'email':
            // eslint-disable-next-line no-case-declarations
            const val = fieldset.querySelector('input').value
            if (!this.options.regex.email.test(val)) {
              fieldset
                .querySelector('input')
                .classList.remove('border-transparent')
              fieldset
                .querySelector('input')
                .classList.add('border-brand-red', 'border-2')
            } else {
              fieldset
                .querySelector('input')
                .classList.remove('border-brand-red', 'border-2')
              fieldset
                .querySelector('input')
                .classList.add('border-transparent')
            }
            // eslint-disable-next-line no-case-declarations
            const inputEmailValid = !!val && this.options.regex.email.test(val)

            stepValidated.push(inputEmailValid)
            break
          case 'text':
            // eslint-disable-next-line no-case-declarations
            const inputTextValid = !!fieldset.querySelector('input').value
            stepValidated.push(inputTextValid)
            break
          case 'textarea':
            // eslint-disable-next-line no-case-declarations
            const selectInputs = fieldset.getElementsByTagName('input')
            if (selectInputs.length === 2) {
              const valid = Array.from(selectInputs).some(
                (input) => input.checked
              )
              stepValidated.push(valid)
            } else {
              stepValidated.push(!!fieldset.querySelector('textarea').value)
            }
            break
          case 'checkbox':
            // eslint-disable-next-line no-case-declarations
            const checked = fieldset.querySelector('input').checked
            stepValidated.push(checked)
            break
        }
      }
    })

    return (
      stepValidated.length &&
      stepValidated.every((group) => !!group) &&
      stepValidated.length === requiredFields.length
    )
  }

  formAnswers() {
    const steps = this.$el.querySelectorAll(this.options.stepWrapperClass)
    const answers = []
    Array.from(steps).forEach((step, index, array) => {
      if (array.length !== index + 1) {
        const stepFieldKeys = step.querySelectorAll('[data-form-field-id]')
        answers[index] = []

        Array.from(new Set(stepFieldKeys)).forEach((stepFieldKey) => {
          const question = stepFieldKey.querySelector('[data-form-question]')
            ?.dataset.formQuestion

          if (question) {
            const answer = {
              question,
              answer:
                this.options.formData[`${stepFieldKey.dataset.formFieldId}`],
            }

            answers[index].push(answer)
          }
        })
      }
    })

    return answers
  }

  async formSubmit() {
    const answers = this.formAnswers()
    const {
      fromEmail,
      questionnaireId,
      textarea_last,
      fromName,
      CRAFT_CSRF_TOKEN,
    } = this.options.formData
    const meta = {
      name: fromName,
      email: fromEmail,
      message: textarea_last,
    }
    const formData = {
      questionnaireId,
      CRAFT_CSRF_TOKEN,
      answers: {
        steps: [...answers],
        meta,
      },
    }
    const url = this.$el.action
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
      const data = await response.json()
      if (data.success) {
        this.options.formError = false
        window.location.href = '/beteiligung/vielen-dank'
      } else {
        this.options.formError = true
      }
    } catch (e) {
      this.options.formError = true
    }
  }
}

window.FormSteps = new FormSteps().init()
