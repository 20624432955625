import { gsap, Expo } from 'gsap'

class Intro {
  mount() {
    return {
      open: window.showIntro,
      init: this.init,
      onClose: this.onClose,
    }
  }

  init() {
    const tl = gsap.timeline()
    tl.to(this.$refs.container, { opacity: 1, duration: 0.5 }, '+=0.3')
    tl.to(this.$refs.bgLayer, { opacity: 0.6, duration: 0.5 }, '+=2')
    tl.to(
      this.$refs.partLeft,
      {
        x: '50%',
        y: '-50%',
        marginTop: 0,
        left: 0,
        top: '100%',
        scale: 1,
        duration: 1,
        ease: Expo.easeOut,
      },
      '-=0.5'
    )
    tl.to(
      this.$refs.partRight,
      {
        x: '-50%',
        y: '50%',
        marginTop: 0,
        top: 0,
        left: '100%',
        scale: 1,
        duration: 1,
        ease: Expo.easeOut,
      },
      '-=1'
    )
    tl.fromTo(
      this.$refs.headline,
      { y: '30%', opacity: 0 },
      { y: 0, opacity: 1, duration: 0.3 },
      '-=1'
    )
    tl.fromTo(
      this.$refs.button,
      { y: '30%', opacity: 0 },
      { y: 0, opacity: 1, duration: 0.3 },
      '-=0.1'
    )
  }

  onClose() {
    const tl = gsap.timeline({
      onComplete: () => {
        this.open = false
      },
    })
    tl.to(this.$refs.headline, { y: '50%', duration: 0.3 })
    tl.to(this.$refs.button, { opacity: 0, duration: 0.3 }, '-=0.3')
    tl.to(this.$el, {
      duration: 1,
      opacity: 0,
      filter: 'blur(20px)',
      delay: 0.5,
    })

    document.body.classList.remove('intro-open')
  }
}

window.Intro = new Intro().mount()
