import Swiper from 'swiper'
import { Grid, Navigation, Pagination } from 'swiper/modules'

import 'swiper/swiper-bundle.css'

class Slider {
  constructor() {
    this.showPrev = false
    this.showNext = true
  }
  mount() {
    return {
      init: this.init,
      onNext: this.onNext,
      onPrev: this.onPrev,
      slideCount: this.count,
      currentSlide: this.currentSlide,
    }
  }
  init() {
    new Swiper(this.$el, {
      modules: [Grid, Navigation, Pagination],
      grid: {
        fill: 'row',
        rows: 2,
      },
      slidesPerView: 2,
      spaceBetween: 20,
      breakpoints: {
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
      navigation: { nextEl: this.$refs.nextBtn, prevEl: this.$refs.prevBtn },
      pagination: {
        el: this.$refs.pagination,
        type: 'fraction',
      },
      on: {
        activeIndexChange: (swiper) => {
          this.showPrev = !swiper.isBeginning
          this.showNext = !swiper.isEnd
        },
      },
    })
  }
  onNext() {
    this.$el.swiper.slideNext()
  }
  onPrev() {
    this.$el.swiper.slidePrev()
  }
}

window.Slider = new Slider().mount()
