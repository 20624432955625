class Navigation {
  constructor() {
    this.open = false
    this.searchActive = false
    this.contrastActive = false
    this.pageHeader = false
    this.selectedSection = null
  }
  mount() {
    return {
      open: this.open,
      searchActive: this.searchActive,
      contrastActive: this.contrastActive,
      pageHeader: this.pageHeader,
      selectedSection: this.selectedSection,
      init: this.init,
      toggleMenu: this.toggleMenu,
      toggleSubMenu: this.toggleSubMenu,
      isSelected: this.isSelected,
      isSecondLevelOpen: this.isSecondLevelOpen,
      onContrastToggle: this.onContrastToggle,
      checkContrast: this.checkContrast,
      checkPageHeader: this.checkPageHeader,
    }
  }
  init() {
    this.checkContrast()
    this.checkPageHeader()
    this.$watch('open', () => {
      document.body.classList.toggle('menu-open')
      this.checkContrast()
    })
  }

  isSecondLevelOpen() {
    return this.selectedSection !== null
  }

  isSelected(section) {
    return this.selectedSection === section
  }

  onContrastToggle() {
    this.contrastActive = !this.contrastActive

    if (!this.contrastActive) {
      document.documentElement.classList.remove('high-contrast-theme')
      document.cookie = 'contrast=false;expires=null; path=/'
    } else {
      document.documentElement.classList.add('high-contrast-theme')
      document.cookie = 'contrast=true;expires=null; path=/'
    }
  }
  checkContrast() {
    this.contrastActive = document.documentElement.classList.contains(
      'high-contrast-theme'
    )
  }

  checkPageHeader() {
    this.pageHeader = !!document.querySelector('.page-header')
  }

  toggleMenu() {
    if (this.open) {
      this.selectedSection = null
    }
    this.open = !this.open
  }

  toggleSubMenu(id) {
    if (this.selectedSection === id) {
      this.selectedSection = null
    } else {
      this.selectedSection = id
    }
  }
}
window.Navigation = new Navigation().mount()
