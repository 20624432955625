class Cookies {
  constructor() {
    this.options = {
      hidden: false,
    }
  }

  init() {
    return {
      options: { ...this.options },
      onDiscard: this.onDiscard,
      onAccept: this.onAccept,
      saveToCookie: this.saveToCookie,
    }
  }

  onDiscard() {
    this.options.hidden = true
    this.saveToCookie(null, null, 1)
  }

  onAccept(item, days) {
    this.options.hidden = true
    this.saveToCookie(item, days)
  }

  saveToCookie(item, days) {
    let now = new Date()
    now.setDate(now.getDate() + days)

    document.cookie = `${item}=true;expires=${now.toUTCString()}; path=/`
  }
}

window.Cookies = new Cookies().init()
