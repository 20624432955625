/**
 * Controls header background color (white/transparent)
 *
 */
class StageIntersectionObserver {
  constructor() {
    this.init()
    this.headerLogo()
  }

  init() {
    if (!window.matchMedia('(min-width: 768px)').matches) {
      return
    }
    const stage = document.querySelector('[data-stage]')
    if (stage) {
      const observer = new IntersectionObserver(
        (entry) => {
          const header = document.querySelector('[data-header]')
          const headerText = document.querySelector('[data-text-color]')
          const headerTextColor = headerText
            ? headerText.getAttribute('data-text-color')
            : 'black'
          const headerBg = document.querySelector('[data-header-bg]')

          if (entry[0].isIntersecting) {
            if (header.classList.contains('contrast-active')) {
              if (header.classList.contains('page-header')) {
                header.classList.remove('text-black')
                header.classList.add('text-white')
              } else {
                header.classList.remove('text-white')
                header.classList.add('text-black')
              }
            } else {
              if (header.classList.contains('page-header')) {
                header.classList.remove('text-white')
                header.classList.add('text-black')
                header.classList.add('text-' + headerTextColor)
              } else {
                header.classList.remove('text-white')
                header.classList.add('text-black')
              }
            }
            headerBg.classList.add('text-' + headerTextColor)
            headerBg.classList.remove('bg-white')
          } else if (!entry[0].isIntersecting) {
            header.classList.remove('text-white')
            headerBg.classList.add('bg-white')
            headerBg.classList.remove('text-white')
            header.classList.add('text-black')
          }
        },
        {
          root: null,
          threshold: 0,
        }
      )
      observer.observe(stage)
    }
  }

  headerLogo() {
    if (window.matchMedia('(min-width: 768px)').matches) {
      return
    }
    const stage = document.querySelector('[data-stage]')
    if (stage) {
      const observer = new IntersectionObserver(
        (entry) => {
          const header = document.querySelector('[data-header-logo]')
          const headerBg = document.querySelector('[data-header-logo-bg]')
          const headerTextColor = header.getAttribute('data-text-color')

          if (entry[0].isIntersecting) {
            headerBg.classList.remove('bg-brand-plaster')
            header.classList.add('text-' + headerTextColor)
          } else if (!entry[0].isIntersecting) {
            header.classList.remove('text-white')
            headerBg.classList.add('bg-brand-plaster')
            header.classList.add('text-black')
          }
        },
        {
          root: null,
          threshold: 0,
        }
      )
      observer.observe(stage)
    }
  }
}

export default new StageIntersectionObserver()
